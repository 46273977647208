import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import CommentsDialog from './CommentsDialog';
import { Badge } from '@mui/material';

const styles = (theme) => ({});

const CommentsButton = (props) => {

    const [showDialog, setShowDialog] = useState(false);

    const handleClickIcon = () => {
        setShowDialog(true);
    };

    // Handles closing the dialog and optionally triggering unmountSearchResults
    const handleCloseDialog = (commentAdded) => {
        setShowDialog(false);
        if (commentAdded && props.unmountSearchResults) {
            props.unmountSearchResults();
        }
    };

    // Determine the number of comments to display in the badge
    const commentCount = props.comments && Array.isArray(props.comments) ? props.comments.length : 0;

    return (
        <div>
            <Tooltip title={props.actionsConfig.comments.label}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    onClick={handleClickIcon}
                    color="secondary"
                    size="medium"
                >
                    {commentCount > 0 ? (
                        <Badge badgeContent={commentCount} color="primary">
                            <i className="material-icons">comment</i>
                        </Badge>
                    ) : (
                        <i className="material-icons">comment</i>
                    )}
                </IconButton>
            </Tooltip>


            {
                showDialog && (
                <CommentsDialog
                    boxDocID={props.boxDocID}
                    fileUrl={props.fileUrl}
                    userDetails={props.userDetails}
                    actionsConfig={props.actionsConfig}
                    handleCloseDialog={handleCloseDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    comments={props.comments}
                    documentDetails={props.documentDetails}
                />
                )
            }
        </div>
    );
};

CommentsButton.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileUrl: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    calledFromDocumentMenu: PropTypes.bool,
    handleCloseMenu: PropTypes.func,
    actionsConfig: PropTypes.object.isRequired,
    comments: PropTypes.array, // when document and comment source is elastic
    unmountSearchResults: PropTypes.func, // to refresh counter in search results after adding comment (elastic only)
    documentDetails: PropTypes.object.isRequired
};

export default withStyles(styles)(CommentsButton);
