import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';

const DownloadFile = ({ boxDocID, fileName, userDetails, downloadFileActionConfig, triggerRefreshAuthToken, fileUrl }) => {
    const [isFetching, setIsFetching] = useState(false);

    const download = async () => {
        setIsFetching(true);
        let url = "";
        let request = {};
        let files = [boxDocID];
        let downloadFileName = fileName;

        // Determine the download URL and request options based on document source
        if (window.REACT_APP_DOCUMENT_SOURCE === "elastic") {
            if (downloadFileActionConfig.preSignedUrl?.enabled) {
                url = fileUrl;
                downloadFileName = fileName ? fileName.replace(/\.[^/.]+$/, ".pdf") : "";
            } else {
                url = `${window.REACT_APP_CONTENT_API_BASE_URL}/api/elastic/document/content/${boxDocID}`;
                request = {
                    headers: {
                        "Authorization": `Bearer ${userDetails.accessToken}`
                    }
                };
                if (window.REACT_APP_CASE_TOKEN_REQUIRED === 'true') {
                    request.headers["case-token"] = userDetails.caseAccessToken;
                }
            }
        } else {
            url = `${window.REACT_APP_CONTENT_API_BASE_URL}${window.REACT_APP_CONTENT_API_DOCUMENT}/download?userId=${userDetails.boxId}`;
            request = {
                method: 'POST',
                headers: { "Authorization": `Bearer ${userDetails.accessToken}` },
                body: files
            };
        }

        // Refresh authentication token before making the request
        await triggerRefreshAuthToken();
        fetch(url, request)
            .then(response => response.ok ? response.blob() : null)
            .then(result => {
                if (result) {
                    const blobUrl = URL.createObjectURL(result);

                    //create new anchor element
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.target = "_blank";
                    a.download = downloadFileName || 'download';
                    a.click();
                    enqueueSnackbar("Download complete", { variant: 'success' });
                }
                setIsFetching(false);
            })
            .catch(e => {
                console.error("Download Exception:", e);
                setIsFetching(false);
                enqueueSnackbar("Error downloading file: " + e, { variant: 'error' });
            });
    };

    const progressMessage = `Downloading ${fileName}`;
    const tooltipTitle = `${downloadFileActionConfig.label} ${fileName}`;

    return (
        <Tooltip title={isFetching ? progressMessage : tooltipTitle}>
            <span>
                <IconButton
                    aria-label="Menu"
                    color="secondary"
                    onClick={download}
                    disabled={isFetching}
                    size="medium"
                >
                    {isFetching ? <CircularProgress color="secondary" size={20} /> : <i className='material-icons md-36'>file_download</i>}
                </IconButton>
            </span>
        </Tooltip>
    );
};

DownloadFile.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    downloadFileActionConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    fileUrl: PropTypes.string
};

export default DownloadFile;
