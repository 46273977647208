import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import Preview from "../preview/Preview";
import { withStyles } from '@mui/styles';
import { Grid } from "@mui/material";
import CommentsPanel from "./CommentsPanel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = theme => ({});

const CommentsDialog = (props) => {

    const [open, setOpen] = useState(true);
    const debug= window.location.pathname.toLowerCase().includes("debug");


    useEffect(() => {
        if (debug) {
            console.log('CommentsDialog props=', props);
            console.log('boxDocId:', props.boxDocID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    // Handles closing the dialog
    const handleCloseDialog = (commentsAdded) => {

        setOpen(false);

        // Added this to address the issue of dialog not re-opening after the initial open
        if (props.handleCloseDialog) {
            props.handleCloseDialog(commentsAdded);
        }
    };

    return (
        <Dialog
            open={open}
            //onClose={handleCloseDialog} - remove to prevent closing of dialog via backdropClick
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xl"
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                color={"secondary"}
                sx={{ position: 'absolute', right: 0, top: 0 }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent style={{ padding: '32px' }}>
                <Grid container spacing={1} style={{ height: "600px" }}>
                    <Grid item xs={8} style={{ height: "100%", overflowY: "auto" }}>
                        <Preview
                            boxDocID={props.boxDocID}
                            fileUrl={props.fileUrl}
                            userDetails={props.userDetails}
                            showHeader={true}
                            showSidebar={false}
                            actionsConfig={props.actionsConfig}
                            triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        />
                    </Grid>

                    <Grid item xs={4} style={{ height: "100%" }}>
                        <CommentsPanel
                            boxDocID={props.boxDocID}
                            userDetails={props.userDetails}
                            actionsConfig={props.actionsConfig}
                            handleCloseDialog={handleCloseDialog}
                            triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                            comments={props.comments}
                            documentDetails={props.documentDetails}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    props.handleCloseDialog &&
                        <Button onClick={handleCloseDialog} variant="contained" color="secondary">Close</Button>
                }
            </DialogActions>
        </Dialog>
    );
};

CommentsDialog.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    comments: PropTypes.array, // When document and comment source is elastic
    enableAddComment: PropTypes.bool,
    documentDetails: PropTypes.object.isRequired,
    fileUrl: PropTypes.string
};

export default withStyles(styles)(CommentsDialog);
