import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from "@mui/material/FormControl/FormControl";
import Typography from '@mui/material/Typography';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from '@mui/material/Divider';
import Moment from 'react-moment';
import Avatar from '@mui/material/Avatar';
import { withStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getErrorMessageFromResponse } from "../../common/helper";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";



const styles = theme => ({
    sidePanelHeader: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(1),
    },
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" },
});

function getInitials(name) {
    let initials = "";
    if (name) {
        initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }
    return initials;
}

// generate a color based on the user's name (for Avatar background)
function getColour(name) {
    const saturation = 30;
    const lightness = 60;
    let hash = 0;

    if (name) {
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
    }

    let h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

const CommentsPanel = (props) => {
    const { classes, boxDocID, userDetails, actionsConfig, triggerRefreshAuthToken, documentDetails } = props;

    const [inputComment, setInputComment] = useState("");
    const [commentsList, setCommentsList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    // Fetch comments when the component mounts
    useEffect(() => {
        getComments();

        const interval = setInterval(() => getComments(), actionsConfig.comments.viewCommentsPollingIntervalMilliseconds);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getComments = async () => {
        setIsFetching(true);

        await triggerRefreshAuthToken();

        const source = window.REACT_APP_COMMENT_SOURCE;
        const pathVar = boxDocID;
        let url;
        let request;

        if (source === "elastic") {
            url = window.REACT_APP_BASE_URL_SCREENING + "/document/" + pathVar + "/comment";
            request = {
                headers: {
                    "case-token": userDetails.caseAccessToken,
                    "Authorization": "Bearer " + userDetails.accessToken,
                }
            };
        } else {
            const params = "?userId=" + userDetails.boxId;
            url = window.REACT_APP_COMMENT_API_BASE_URL + window.REACT_APP_COMMENT_API_DOCUMENT + "/" + pathVar + params;
            request = {
                headers: {"Authorization": "Bearer " + userDetails.accessToken,}
            };
        }

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    try {
                        return response.json();
                    } catch (err) {
                        enqueueSnackbar(getErrorMessageFromResponse(response, 'retrieving comments'), { variant: 'error' });
                        return null;
                    }
                } else {
                    enqueueSnackbar(getErrorMessageFromResponse(response, 'retrieving comments'), { variant: 'error' });
                    clearInterval();
                    return null;
                }
            })
            .then(result => {
                setIsFetching(false);
                setCommentsList(result);
            })
            .catch(e => {
                enqueueSnackbar("Error retrieving comments " + e, { variant: 'error' });
                setIsFetching(false);
                clearInterval();
            });
    };


    const handleOnChangeComment = (event) => {
        setInputComment(event.target.value);
    };

    const postComment = async () => {
        const pathVar = boxDocID;
        let url = null;
        let request = null;
        const source = window.REACT_APP_COMMENT_SOURCE;

        await triggerRefreshAuthToken();

        if (source === "elastic") {
            url = window.REACT_APP_BASE_URL_SCREENING + "/document/" + pathVar + "/comment";

            const body = { comment: inputComment };

            request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "case-token": userDetails.caseAccessToken,
                    "Authorization": "Bearer " + userDetails.accessToken,
                },
                body: JSON.stringify(body),
            };

        } else {
            const queryStr = "?userId=" + userDetails.boxId;
            url = window.REACT_APP_COMMENT_API_BASE_URL + window.REACT_APP_COMMENT_API_DOCUMENT + queryStr;

            const body = {
                item: {
                    "type": "file",
                    "id": boxDocID
                },
                message: inputComment,
                type: "comment"
            };

            request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + userDetails.accessToken,
                },
                body: JSON.stringify(body),
            };
        }

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    setInputComment(""); // Clear the input field after posting
                    getComments(); // Refresh comments
                } else {
                    enqueueSnackbar(getErrorMessageFromResponse(response, 'posting comment'), { variant: 'error' });
                }
            })
            .catch(e => {
                enqueueSnackbar("Error posting comment " + e, { variant: 'error' });
            });
    };

    // Check if the "Add Comment" button should be enabled
    let enableAddComment = true;
    const actionConfig = actionsConfig.comments;
    const disableWhen = actionConfig.disableAddCommentWhen;

    let key = disableWhen && disableWhen.templateKey + "~" + disableWhen.metadataKey;
    if (disableWhen && disableWhen.templateKey === "n/a") {
        key = disableWhen.metadataKey;
    }
    if (disableWhen && Object.entries(disableWhen).length > 0 && documentDetails) {
        if (documentDetails[key] && (disableWhen.values.indexOf(documentDetails[key]) >= 0)) {
            enableAddComment = false;
        }
    }

    const source = window.REACT_APP_COMMENT_SOURCE;
    const userName = source === "elastic" ? userDetails.userEmail : userDetails.userName;

    return (
        <Grid container spacing={1} style={{ height: "600px" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
                <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} color={"textPrimary"} className={classes.sidePanelHeader}>
                            Comments
                        </Typography>

                        <div className={classes.sidePanelHeader}>
                            {
                                isFetching ?
                                    <LinearProgress variant={"indeterminate"} color={"secondary"} /> :
                                    <div style={{ height: "4px", backgroundColor: "transparent" }} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ height: "calc(100% - 170px)", overflowY: "auto" }}>
                        <List>
                            {commentsList &&
                                commentsList.map((comment, index) => {
                                    const autoFocus = index === (commentsList.length - 1);
                                    const name = source === "elastic" ? comment.created_by : comment.created_by.name;
                                    return (
                                        <React.Fragment key={comment.id}>
                                            <ListItem autoFocus={autoFocus} button style={{ pointerEvents: "none" }}>
                                                <ListItemIcon>
                                                    <Avatar style={{ backgroundColor: getColour(name) }}>{getInitials(name)}</Avatar>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={comment.message}
                                                    secondary={
                                                        <Typography variant="body2" color="textSecondary">
                                                            <Moment fromNow>{comment.created_at}</Moment> by {name}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            {index !== (commentsList.length - 1) && <Divider />}
                                        </React.Fragment>
                                    );
                                })}
                        </List>
                    </Grid>
                    {
                        enableAddComment &&
                        <Grid item xs={12} style={{height: "150px", overflowY: "auto",}}>
                            <List>
                                <ListItem button={false}>
                                    <ListItemIcon>
                                        <Avatar
                                            style={{backgroundColor: getColour(userName)}}>{getInitials(userName)}</Avatar>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <FormControl  variant="standard" fullWidth >
                                            {/*<InputLabel htmlFor="inputComment">Comment</InputLabel>*/}
                                            <TextField
                                                id="inputComment" name="inputComment"
                                                value={inputComment}
                                                placeholder="Write a comment" type="text" margin="none" variant="outlined" autoComplete=""
                                                onChange={handleOnChangeComment}
                                                autoFocus = {false} // autofocus on last comment instead to ensure it scrolls to latest comment
                                                multiline rows={3}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Tooltip title={"Post"}>
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="Post comment"
                                                                    onClick={postComment}
                                                                    edge="end"
                                                                    disabled={inputComment === ""}
                                                                    size="medium" color={"secondary"}>
                                                                    <i className="material-icons">send</i>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        </Tooltip>)
                                                }}
                                            />
                                        </FormControl>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

CommentsPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    documentDetails: PropTypes.object.isRequired,
};

export default withStyles(styles)(CommentsPanel);
