import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ContentExplorer } from "box-ui-elements";
import messages from "box-ui-elements/i18n/en-US";
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import 'box-ui-elements/dist/explorer.css';

addLocaleData(enLocaleData);

//const LANGUAGE = 'en_UK';
const LANGUAGE = "en-US";

const MyDocumentsDialog = ({ userDetails, handleMyDocumentsClose, myDocumentsFolder }) => {
    const [open, setOpen] = useState(true);
    const [folderChosen, setFolderChosen] = useState({});

    const handleCloseDialog = () => {
        setOpen(false);
        handleMyDocumentsClose();
    };

    const folderPicker = (results) => {
        console.log('folder chosen', results);
        if (results && Array.isArray(results)) {
            if (results.length > 1) {
                alert('Please choose only one folder');
            } else {
                setFolderChosen(results[0]);
                alert("New Drawing Register folder will be created in the folder: " + results[0].name + ". Please enter contract name and reference");
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            {/* Close button */}
            <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                color="secondary"
                sx={{ position: 'absolute', right: 0, top: 0 }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <div style={{ border: '1px solid #eee', height: '350px', width: '100%' }}>

                    <ContentExplorer
                        token={userDetails.boxAccessToken}
                        language={LANGUAGE}
                        messages={messages}
                        logoUrl={window.location.origin + '/images/authlogo.png'}
                        currentFolderId={0}
                    />


                    {/*<IntlProvider locale="en">*/}
                    {/*    <ContentPicker*/}
                    {/*        token={props.userDetails.boxAccessToken}*/}
                    {/*        rootFolderId={"0"}*/}
                    {/*        logoUrl = {window.location.origin + '/images/authlogo.png'}*/}
                    {/*        type={"folder"}*/}
                    {/*        currentFolderId={"224040009260"}*/}
                    {/*        onChoose={folderPicker}*/}
                    {/*        maxSelectable={1}*/}
                    {/*        canUpload={false}*/}
                    {/*        canCreateNewFolder={false}*/}
                    {/*        canSetShareAccess={false}*/}
                    {/*        //responseInterceptor={folderPicker}*/}
                    {/*    />*/}
                    {/*</IntlProvider>*/}




                    {/*<IntlProvider locale="en">*/}
                    {/*    <ContentPicker*/}
                    {/*        features={FEATURES}*/}
                    {/*        rootFolderId={FOLDER_ID}*/}
                    {/*        token={props.userDetails.boxAccessToken}*/}
                    {/*        {...PROPS}*/}
                    {/*    />*/}
                    {/*</IntlProvider>*/}



                </div>
            </DialogContent>

            <DialogActions>
                {/* Close button in actions */}
                <Button onClick={handleCloseDialog} variant="contained" color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

MyDocumentsDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleMyDocumentsClose: PropTypes.func.isRequired,
    myDocumentsFolder: PropTypes.string.isRequired
};

export default MyDocumentsDialog;
